.hamburger{
width:2rem;
height:2rem;
display:flex;
justify-content:space-around;
flex-direction: column;
flex-wrap: nowrap;
z-index:10;
}
.burger{
width:2rem;
height:0.25rem;
border-radius:20px;
background-color:black;
transform-origin:10px;
transition:all 0.3s linear;
 
}