* {
    margin: 0;
    padding: 0;
   
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.homemain {
    display: flex;
    background-color:rgb(246, 244, 244);
    height: 40vh;
    align-items: center;
    justify-content: space-evenly;
}

.homemain .homebox1 {
    background-color: white;
    width: 45%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 6.6px 13.1px 13.1px hsl(0deg 0% 0% / 0.30);

}

.homemain .homebox1 .homename {
    padding-top: 18%;
    text-align: center;
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: normal;
    color: blue;
}


.homemain .homebox2 {
    background-color: white;
    width: 45%;
    height: 90%;
    border-radius: 5px;
    box-shadow: 6.6px 13.1px 13.1px hsl(0deg 0% 0% / 0.30);
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: normal;
    color:blue;
    text-align: center;
}
.homebox1 .add{
    padding: 0px 10px;
    gap: 5px;
}
.homebox1 .add .phno{
    font-size: 20px;
}



@media only screen and (max-width: 480px) {
    .homemain {
        display: flex;
        flex-direction: column;
        width: 411px;
        height:500px ;
        align-items: center;
        justify-content: space-evenly;
        gap: 30px;
        padding: 5px 7px;
    }

    
.homemain .homebox1 {
    background-color: white;
    width: 90%;
    height: 60vh;
    border-radius: 5px;
    box-shadow: 6.6px 13.1px 13.1px hsl(0deg 0% 0% / 0.30);

}

.homemain .homebox1 .homename {
    padding-top: 20%;
    text-align: center;
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: normal;
    color: blue;
}


.homemain .homebox2 {
    background-color: white;
    width: 95%;
    height: 30vh;
    border-radius: 5px;
    box-shadow: 6.6px 13.1px 13.1px hsl(0deg 0% 0% / 0.30);
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: normal;

}
.homebox2 .add{
    padding: 40px 10px;
    gap: 5px;
   
}

.homebox2 .add .phno{
    font-size: 15px;
}
.homebox2 .add .email{
    font-size: 15px;
}
.homebox2 .add .address{
    font-size: 15px;

}

}